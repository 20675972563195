/**
 * Überschriebene Bootstrap Variablen
 */

$font-family-sans-serif:           "Open Sans", "Helvetica Neue", helvetica, arial, sans-serif;
$headings-font-weight:             600;
$font-size-base:                   13px;

// Diese Einstelung verhindert, dass das Menü der navbar auf Mobilgeräten ausgeblendet wird.
$grid-float-breakpoint:            0;

$navbar-height:                    50px;
$navbar-inverse-bg:                rgba(51, 51, 51, 0.9);
$navbar-inverse-brand-color:       white;
$navbar-inverse-brand-hover-color: white;

$panel-body-padding:               10px;

/**
* Eigene Variabeln.
 */

$wizard-transition-duration:   0.5s;
