/** TEXT BOCKS **/
.content-block {
    border-color: #ccc;
    border-bottom-style: dashed;
    border-width: 0;
    margin: 10px 5px;
}

.content-block-border-bottom {
    border-bottom-width: 1px;
}

/** TABS **/
.nav-tabs > li.active > a,
.nav-tabs > li.active > a:hover,
.nav-tabs > li.active > a:focus {
    font-weight: 600;
}

.btn-action {
    color: $brand-primary;
    transition: all 0.3s ease-in-out;
    padding: 5px 10px;
    background-color: #fff;
    border-radius: 5px;

    &:hover {
        color: #fff;
    }

    &:disabled:hover {
        color: $brand-primary;
    }
}

.btn-action-add {
    &:hover {
        background-color: $brand-primary;
    }
}

.btn-action-edit:hover {
    background-color: $brand-warning;
}

.btn-action-delete {
    &:hover {
        background-color: $brand-danger;
    }
}

.toolbar {
    display: flex;

    > .btn,
    > .btn-group {
        margin-right: 4px;
    }

    .filter {
        margin-left: auto;
        width: 40%;

        .has-value {
            background-color: rgb(222, 243, 250);
        }
    }
}

.splash {
    text-align: center;
    margin-top: 5vh;
}
