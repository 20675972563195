/* Sortable tables */
.sorting {
    background: transparent;

    &::after {
        @extend .#{$fa-css-prefix};

        content: $fa-var-sort;
        font-family: FontAwesome;
        float: right;
        color: rgba($gray-dark, 0.5);
    }

    &.asc::after {
        content: $fa-var-sort-asc;
        color: $gray-dark;
    }

    &.desc::after {
        content: $fa-var-sort-desc;
        color: $gray-dark;
    }
}
