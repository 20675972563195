.progress-bar-storage {
    @include progress-bar-variant(#ccc);

    span {
        display: inline-block;
        padding-left: 10px;
        color: #555;
        white-space: nowrap;
    }
}

td .progress {
    margin-bottom: 0;
}

.form-group .progress {
    height: 30px;
    margin: 3px 0 0;

    span {
        line-height: 30px;
    }
}
