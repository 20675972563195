.login-box {
    margin-top: 15%;
}

.login-panel .panel-body {
    padding: 15px;
}

.login-panel.invalid {
    animation: shake 0.5s linear;
}

@keyframes shake {
    8%,
    41% {
        transform: translateX(-10px);
    }

    25%,
    58% {
        transform: translateX(10px);
    }

    75% {
        transform: translateX(-5px);
    }

    92% {
        transform: translateX(5px);
    }

    0%,
    100% {
        transform: translateX(0);
    }
}
