.wizard-nav {
    margin-bottom: 1.5em;
    display: none;

    .fa-chevron-right {
        line-height: inherit;
        float: right;
        display: none;
    }
}

@media (min-width: $screen-md-min) {
    .wizard-nav {
        display: block;
    }
}

@media (min-width: $screen-lg-min) {
    .wizard-nav {
        margin-bottom: 0;

        // Entspricht genau .nav-stacked von Boostrap.
        > li {
            float: none;

            + li {
                margin-top: 2px;
                margin-left: 0; // no need for this gap between nav items
            }
        }
    }

    .wizard-nav .fa-chevron-right {
        display: block;
    }
}

wizard-content .tab-content {
    overflow: hidden;
}

.wizard-wrapper {
    display: flex;
    position: relative;
    top: 0;
    left: 0;
}

.wizard-submit-backdrop {
    position: absolute;
    z-index: 999999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(white, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
}

wizard-inner-content {
    flex: 1 0 100%;
}

wizard-inner-content.forward,
wizard-inner-content.backward {
    &.ng-enter,
    &.ng-leave {
        transition: transform $wizard-transition-duration ease-in-out, opacity $wizard-transition-duration linear;
    }
}

wizard-inner-content.forward {
    &.ng-enter {
        order: 2;
        opacity: 0;
        transform: translateX(0);
    }

    &.ng-enter-active {
        opacity: 1;
        transform: translateX(-100%);
    }

    &.ng-leave {
        order: 1;
        opacity: 1;
        transform: translateX(0);
    }

    &.ng-leave-active {
        opacity: 0;
        transform: translateX(-100%);
    }
}

wizard-inner-content.backward {
    &.ng-enter {
        order: 1;
        opacity: 0;
        transform: translateX(-100%);
    }

    &.ng-enter-active {
        opacity: 1;
        transform: translateX(0);
    }

    &.ng-leave {
        order: 2;
        opacity: 1;
        transform: translateX(-100%);
    }

    &.ng-leave-active {
        opacity: 0;
        transform: translateX(0);
    }
}
