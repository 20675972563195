@font-face {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    src:
        local("OpenSans"),
        local("OpenSans"),
        url("../fonts/OpenSans-Regular.woff2") format("woff2"),
        url("../fonts/OpenSans-Regular.woff") format("woff"),
        url("../fonts/OpenSans-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    src:
        local("Open Sans Semibold"),
        local("OpenSans-Semibold"),
        url("../fonts/OpenSans-Semibold.woff2") format("woff2"),
        url("../fonts/OpenSans-Semibold.woff") format("woff"),
        url("../fonts/OpenSans-Semibold.ttf") format("truetype");
}

@font-face {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 800;
    src:
        local("Open Sans Bold"),
        local("OpenSans-Bold"),
        url("../fonts/OpenSans-Bold.woff2") format("woff2"),
        url("../fonts/OpenSans-Bold.woff") format("woff"),
        url("../fonts/OpenSans-Bold.ttf") format("truetype");
}
