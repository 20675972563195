html,
body,
#wrapper,
#page-wrapper {
    width: 100%;
    height: 100%;
}

.container {
    margin-top: 20px;
}

h2.page-headline {
    font-size: 20px;
    margin: 5px 0 15px;
}

/* UI Bootstrap */
.nav,
.pagination,
.carousel,
.panel-title a {
    cursor: pointer;
}
