.callout {
    border-left: 5px solid #eee;
    margin: 10px 0;
    padding: 5px 30px 10px 15px;
}

.callout h4 {
    margin: 0;
}

.callout p:last-child {
    margin-bottom: 0;
}

.callout code,
.callout .highlight {
    background-color: #fff;
}

/* Variations */
.callout-danger {
    background-color: #fcf2f2;
    border-color: #dfb5b4;
}

.callout-warning {
    background-color: #fefbed;
    border-color: #f1e7bc;
}

.callout-info {
    background-color: #f0f7fd;
    border-color: #d0e3f0;
}

.callout-success {
    background-color: #dff0d8;
    border-color: #d6e9c6;
}
