body {
    padding-top: $navbar-height;
}

.navbar {
    .fa {
        font-size: 1.9rem;
    }

    li a.active,
    li a.active:hover {
        background-color: $navbar-inverse-link-active-bg;
    }
}
