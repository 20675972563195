/** Pagination **/
.select-limit,
.table-responsive .pagination {
    @extend .pull-right;

    display: inline-block;
    margin: 0 15px 15px 0;
}

.select-limit {
    width: 5.4em;
}

.table-total {
    display: inline-block;
    margin: 0 0 15px 15px;
}

.col-checkbox {
    width: 1%;
}

.table-selectable {
    tr {
        cursor: pointer;

        td,
        th {
            transition: background-color 0.3s ease-in-out;
        }
    }
}

@include table-row-variant("selected", $state-info-bg);
