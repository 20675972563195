label.btn {
    min-height: 35px;
    padding-top: 8px;
}

textarea {
    min-height: 30px;
    width: 100%;
    resize: vertical;
}

.form-group,
.form-cell {
    .help-block {
        display: none;
    }

    &.has-error .help-block {
        display: block;
    }
}

.form-group.required .control-label::after {
    content: "*";
}

.progress {
    margin-bottom: 0;
}

.progress .progress-bar {
    background-color: #ccc;
}

.progress .progress-bar span {
    display: inline-block;
    padding-left: 10px;
    color: #555;
    white-space: nowrap;
}

.form-group .progress {
    height: 30px !important;
    margin: 3px 0 0;
}

.form-group .progress .progress-bar span {
    line-height: 30px;
}

// Icons für den Datepicker
.glyphicon {
    @extend .fa;
}

.glyphicon-chevron-right {
    @extend .fa-chevron-right;
}

.glyphicon-chevron-left {
    @extend .fa-chevron-left;
}
